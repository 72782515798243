import { computed } from 'vue';

export const useBytesFormatter = (locale) => computed(
  () => {
    try {
      return new Intl.NumberFormat(locale.value, {
        style: 'decimal',
        notation: 'compact',
      });
    } catch {
      // some browsers do not have it, so, we just return the input.
      return {
        format: (b) => b,
      };
    }
  },
);
